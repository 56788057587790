<template>
  <div>
    <!--
      @slot header
        @binding {function} prev 이전 월로 이동
        @binding {function} next 다음 월로 이동
    -->
    <slot name="header"
      :prev="prev"
      :next="next"
    ></slot>
    <slot name="adding"></slot>
    <FullCalendar ref="calendar" defaultView="dayGridMonth" :plugins="plugins"
      :locales="locales" :locale="locale" :header="header" :contentHeight="contentHeight"
      :defaultDate="viewDate" :events="events" :eventRender="eventRender"
      :datesRender="onDatesRender"
    ></FullCalendar>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'; // https://fullcalendar.io/docs/vue
import dayGridPlugin from '@fullcalendar/daygrid'; // https://fullcalendar.io/docs/month-view
import koLocale from '@fullcalendar/core/locales/ko';

/**
 * 월 Calendar 컴포넌트 (1일 단위 그리드, dayGridMonth)
 * @see https://fullcalendar.io/docs/month-view
 */
export default {
  name: 'DayGridMonthCalendar',
  components: {
    FullCalendar,
  },
  props: {
    viewDate: {
      type: [Date, String],
      default() {
        return new Date();
      },
    },
    events: {
      type: Array,
      default() {
        return [];
      },
    },
    eventRender: {
      type: Function,
    },
  },
  data() {
    return {
      plugins: [dayGridPlugin],
      locales: [koLocale],
      // locale: this.$i18n.locale, // https://github.com/fullcalendar/fullcalendar/issues/5439
      locale: 'en',
      contentHeight: 'auto',
      header: { left: '', center: '', right: '' },
    };
  },
  watch: {
    viewDate(newViewDate) {
      this.$refs.calendar.getApi().gotoDate(newViewDate);
    },
  },
  methods: {
    prev() {
      this.$refs.calendar.getApi().prev();
    },
    next() {
      this.$refs.calendar.getApi().next();
    },
    /**
     * @see https://fullcalendar.io/docs/datesRender
     */
    onDatesRender() {
      /**
       * 표시되는 기간 (월) 변경시
       * @property {date} 변경된 기준일자
       */
      this.$emit('change', this.$refs.calendar.getApi().getDate());
    },
  },
};
</script>

<style scoped>
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
/*>>> .fc-view > table {*/
/*  width: 100%;*/
/*  !*min-width: 1400px;*!*/
/*}*/

/*>>> .fc-dayGrid-view .fc-body .fc-row {*/
/*  min-height:189px;*/
/*}*/

/*>>> .fc-ltr .fc-axis {*/
/*  position: relative;*/
/*  width: 155px !important;*/
/*  font-size: 14px;*/
/*  font-weight: 500;*/
/*  text-align: center;*/
/*  vertical-align: middle;*/
/*  color: #666;*/
/*  border-bottom: 0;*/
/*}*/

/*>>> .fc-time-grid .fc-slats .fc-minor td {*/
/*  border-top: 0;*/
/*}*/

/*>>> .fc-ltr .fc-axis > span {*/
/*  position: absolute;*/
/*  top: 20px;*/
/*  left: 50%;*/
/*  transform: translateX(-50%);*/
/*  z-index: 10;*/
/*}*/

/*!*>>> .fc-view-container .fc-day.fc-widget-content {*!*/
/*!*  border-bottom: 0;*!*/
/*!*}*!*/

/*>>> .fc-theme-standard th,*/
/*>>> .fc-unthemed th {*/
/*  height:48px;*/
/*  border-width:0 0 1px;*/
/*  vertical-align:middle;*/
/*  font-size: 14px;*/
/*  font-weight:700;*/
/*  text-align:center;*/
/*  text-transform: uppercase;*/
/*}*/

/*>>> .fc-theme-standard td,*/
/*>>> .fc-unthemed td {*/
/*  color: #111;*/
/*}*/

/*>>> .fc,*/
/*>>> .fc table,*/
/*>>> .fc-button {*/
/*  !*font-family: LIFEPLUS;*!*/
/*  font-size: 14px;*/
/*  font-weight: 500;*/
/*  line-height: normal;*/
/*  letter-spacing: normal;*/
/*  color:#666;*/
/*}*/

/*>>> .fc-daygrid-day-top,*/
/*>>> .fc-row .fc-content-skeleton td {*/
/*  padding: 15px 0 26px;*/
/*}*/

/*>>> .fc-daygrid-day-frame {*/
/*  min-height: 189px;*/
/*  padding: 15px 20px;*/
/*}*/

/*>>> .fc-theme-standard .fc-day-sun,*/
/*>>> .fc-sun,*/
/*>>> .fc-sun > .fc-day-number {*/
/*  color: #e50000;*/
/*}*/

/*>>> .fc-other-month > .fc-day-number {*/
/*  opacity: 0.3;*/
/*}*/

/*>>> .fc-day-today,*/
/*>>> .fc-unthemed td.fc-today {*/
/*  background: transparent;*/
/*}*/

/*>>> .fc-day-grid-event .fc-content {*/
/*  white-space: normal;*/
/*  color: #666;*/
/*  word-break: keep-all;*/
/*}*/
/*>>> .fc-event {*/
/*  background:#fff;*/
/*  border:0;*/
/*  font-size: 12px;*/
/*  font-weight: 500;*/
/*  line-height: 1.67;*/
/*  color:#666;*/
/*  margin-top:-10px;*/
/*  position: relative;*/
/*  padding-left: 24px;*/
/*}*/

/*>>> .fc-event:before {*/
/*  content:'';*/
/*  position:absolute;*/
/*  top:2px;*/
/*  left:0;*/
/*  display:block;*/
/*  width:16px;*/
/*  height:16px;*/
/*  border-radius:50%;*/
/*  background-color:#59d993;*/
/*}*/

/*>>> .fc-event.big-event:before {*/
/*  background-color: #3190d6;*/
/*}*/

/*>>> .fc-event.education:before {*/
/*  background-color: #ff5013;*/
/*}*/

/*>>> .fc-event.networking:before {*/
/*  background-color: #59d993;*/
/*}*/

/*>>> .fc-event.others:before {*/
/*  background-color: #97c8ec;*/
/*}*/

/*>>> .fc-day-grid-event .time {*/
/*  display:block;*/
/*  min-height:16px;*/
/*  color:#111;*/
/*  font-size: 12px;*/
/*  font-weight: 700;*/
/*  line-height: 1.67;*/
/*}*/

/*>>> .fc-day-grid-event .title {*/
/*  display:block;*/
/*  margin-top:3px;*/
/*  font-size: 12px;*/
/*  font-weight: 500;*/
/*  line-height: 1.67;*/
/*  color:#666;*/
/*}*/

/*>>> .fc-row .fc-content-skeleton td > .fc-day-number {*/
/*  display:block;*/
/*  float:none;*/
/*  text-align:center;*/
/*  font-size: 16px;*/
/*  font-weight: 500;*/
/*}*/

/*>>> .fc .fc-row .fc-content-skeleton table,*/
/*>>> .fc .fc-row .fc-content-skeleton td,*/
/*>>> .fc .fc-row .fc-mirror-skeleton td {*/
/*  padding:15px 20px;*/
/*}*/
/*>>> .fc-ltr .fc-time-grid .fc-event-container {*/
/*  margin:0;*/
/*}*/

/*.alert-pop {*/
/*  padding: 84px 130px;*/
/*  font-size: 16px;*/
/*  font-weight: 500;*/
/*  color: #111;*/
/*  box-shadow: 0 14px 28px 0 rgba(0, 0, 0, 0.12);*/
/*  background-color: #fff;*/
/*  z-index: 1000;*/
/*  position: absolute;*/
/*  top: 50%;*/
/*  left: 50%;*/
/*  transform: translate(-50%, -50%);*/
/*}*/

/*.alert-pop .txt {*/
/*  font-size: 20px;*/
/*  font-weight: 700;*/
/*  line-height: 1.8;*/
/*  letter-spacing: normal;*/
/*  text-align: center;*/
/*  color: #333333;*/
/*  word-break: keep-all;*/
/*}*/
</style>

<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>

기본 사용 예시 :
```jsx
<DayGridMonthCalendar></DayGridMonthCalendar>
```

Header (Title slot, Button slot) 사용 예시 :
```vue
<template>
  <DayGridMonthCalendar
    :viewDate="viewDate"
    :events="events"
    :eventRender="eventRender"
    @change="onChange">
    <template v-slot:header="slotProps">
      <div class="calendar-header-area">
        <BaseButton type="prev" @click="slotProps.prev">PREV</BaseButton>
        <div class="month">
          {{ viewRangeTitle }}
          <span class="icon-calendar"></span>
        </div>
        <BaseButton type="next" @click="slotProps.next">NEXT</BaseButton>
      </div>
    </template>
  </DayGridMonthCalendar>
</template>

<script>
export default {
  data() {
    return {
      viewDate: this.$moment().toDate(),
      events: [
        {
          id: 1,
          title: '테스트 이벤트1',
          start: this.$moment().add(2, 'days').hours(13).minutes(0).toDate(),
          end: this.$moment().add(2, 'days').hours(15).minutes(0).toDate(),
          classNames: ['big-event'],
        },
        {
          id: 2,
          title: '테스트 이벤트2',
          start: this.$moment().add(2, 'days').hours(17).minutes(0).toDate(),
          end: this.$moment().add(2, 'days').hours(18).minutes(30).toDate(),
          classNames: ['education'],
        },
        {
          id: 3,
          title: '테스트 이벤트3',
          start: this.$moment().add(4, 'days').hours(9).minutes(0).toDate(),
          end: this.$moment().add(4, 'days').hours(13).minutes(0).toDate(),
          classNames: ['networking'],
        },
        {
          id: 4,
          title: '테스트 이벤트4',
          start: this.$moment().add(5, 'days').hours(11).minutes(0).toDate(),
          end: this.$moment().add(5, 'days').hours(13).minutes(0).toDate(),
          classNames: ['others'],
        },
      ],
    };
  },
  computed: {
    viewRangeTitle() {
      return this.$moment(this.viewDate).format(this.$i18n.locale === 'en' ? 'MM.YYYY' : 'YYYY.MM');
    },
  },
  methods: {
    onChange(newViewDate) {
      this.viewDate = newViewDate
    },
    /**
     * https://fullcalendar.io/docs/eventRender
     */
    eventRender(info) {
      const { event, el } = info;
      const time = `${this.$moment(event.start).format('HH:mm')} ~ ${this.$moment(event.end).format('HH:mm')}`
      el.innerHTML = `
        <span class='bullet'></span>
        <span class='time'>${time}</span>
        <span class='title'>${event.title}</span>
      `;
    },
  },
}
</script>

<style scoped>
.calendar-header-area {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.calendar-header-area .month {
  display: flex;
  align-items: center;
  height: 100%;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  color:#111;
  padding:0 24px 0 30px;
}
</style>
```
</docs>
