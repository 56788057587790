<template>
  <div class="contents" id="contents">
    <!--  Visual Section -->
    <section id="visual-section" class="section visual-section">
      <video
        id="eventVideo1"
        autoplay
        muted
        class="event-video"
        @ended="videoChange($event, 2)"
      >
        <source :src="getMediaFileURL(videoName1)" type="video/mp4">
      </video>

      <video
        id="eventVideo2"
        style="display:none;"
        muted
        class="event-video"
        @ended="videoChange($event, 1)"
      >
        <source :src="getMediaFileURL(videoName2)" type="video/mp4">
      </video>

      <div class="layer-over">
        <div class="title-wrap">
          <h2 class="ani-text-up">EVENT</h2>
          <div class="title-desc ani-text-up" v-html="$t('content.event.Event.txt01')">
          </div>
        </div>
      </div>
    </section>
    <!--  // Visual Section -->

    <!-- Up Coming Event Section -->
    <section class="slide-section"
      v-show="swiperEventList && swiperEventList.length > 0">
      <h4>UPCOMING EVENT</h4>
      <!-- Swiper -->
      <Swiper ref="mySwiper"
        :options="swiperOptions"
        class="event-list"
      >
        <template v-for="event in swiperEventList">
          <swiper-slide>
            <template v-if="event.name" >
              <router-link
                :to="{ name:'EventDetail',
                  params: {eventNo:event.id},
                  query: {Date:$moment(viewDate).format('YYYYMM')}
                }"
                tag="a"
                class="event-item"
                :class="{'past-event': event.startDate < nowDate }"
              >
                <template v-if="event.startDate" >
                  <figure class="event-pic">
                    <!--
                      ie 11에서 grayscale 지원하기 위해서 svg filter 사용 (우선 전체 브라우저에 적용)
                      - https://developer.mozilla.org/en-US/docs/Web/SVG/Element/filter
                      - https://github.com/karlhorky/gray
                    -->
                    <svg
                      v-if="event.startDate < nowDate"
                      width="100%" height="100%"
                      viewBox="0 0 260 260"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <title>이벤트 이미지</title>
                      <filter id="grayscale">
                        <feColorMatrix type="saturate" values="0"/>
                      </filter>
                      <image
                        x="0" y="0"
                        width="260" height="260"
                        :xlink:href="event.thumbnailFileUrl"
                        preserveAspectRatio="none"
                        filter="url(#grayscale)" />
                    </svg>
                    <!-- // ie 11에서 grayscale 지원하기 위해서 svg filter 사용 -->
                    <img
                      v-else
                      :src="event.thumbnailFileUrl" alt="이벤트 이미지" />
                  </figure>
                  <dl class="event-info">
                    <dt>{{event.name}}</dt>
                    <dd>
                      <span>{{ getDateString(event.startDate, event.endDate) }}</span>
                    </dd>
                  </dl>
                </template>
              </router-link>
            </template>
          </swiper-slide>
        </template>
        <div class="swiper-scrollbar" slot="scrollbar"></div>
      </Swiper>
      <!-- // Swiper -->
    </section>
    <!-- // Up Coming Event Section -->

    <!-- Calendar Section -->
    <section class="calendar-section" data-aos="fade-up">
      <div class="inner-lg">
        <DayGridMonthCalendar
          :viewDate="viewDate"
          :events="events"
          :eventRender="eventRender"
          @change="onChange"
        >
          <template v-slot:header="slotProps">
            <div class="calendar-header-area">
              <BaseButton type="prev" initial-view="true" @click="slotProps.prev">PREV</BaseButton>
              <div class="month">
                {{ viewRangeTitle }}
                <datepicker v-model="viewDate" ref="calendar" input-class="hide"></datepicker>
                <span class="icon-calendar" @click="openCalendar"></span>
              </div>
              <BaseButton type="next" @click="slotProps.next">NEXT</BaseButton>
            </div>
          </template>
          <template v-slot:adding>
            <StaggerTransition targets="li">
            <ul class="event-indicator ani-stagger-right">
              <li class="business">{{$t('commonCode.EV101')}}</li>
              <li class="networking">{{$t('commonCode.EV103')}}</li>
              <li class="conference">{{$t('commonCode.EV102')}}</li>
              <li class="promotion">{{$t('commonCode.EV105')}}</li>
              <li class="others">{{$t('commonCode.EV104')}}</li>
            </ul>
            </StaggerTransition>
          </template>
        </DayGridMonthCalendar>
      </div>
    </section>
    <!-- // Calendar Section -->
  </div>
</template>

<script>
import {
  mixin as mixinHelperUtils,
  mixinMotion,
  format,
} from '@/common/helperUtils';
import { APIs, FilePaths } from '@/common/portalApi';
import BaseButton from '@/components/base/BaseButton.vue';
import DayGridMonthCalendar from '@/components/calendar/DayGridMonthCalendar.vue';
import Datepicker from '@sum.cumo/vue-datepicker';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
// import { gsap } from '@/common/motions';
import StaggerTransition from '@/components/transition/StaggerTransition.vue';
import 'swiper/css/swiper.css';
import '@sum.cumo/vue-datepicker/dist/vuejs-datepicker.css';

export default {
  name: 'EventList',
  mixins: [mixinHelperUtils, mixinMotion],
  components: {
    Swiper,
    SwiperSlide,
    BaseButton,
    DayGridMonthCalendar,
    Datepicker,
    StaggerTransition,
  },
  metaInfo() {
    return {
      meta: this.$route.meta.metaInfo
    };
  },
  data() {
    return {
      // 제거 바람
      nowDate: this.$moment().format('YYYY.MM.DD'), // 2
      format,
      layerCalendarDate: undefined,
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 12,
        centeredSlides: true,
        centeredSlidesBounds: true,
        centerInsufficientSlides: true,
        freeMode: true,
        freeModeSticky: true,
        simulateTouch: false,
        scrollbar: {
          el: '.swiper-scrollbar',
          hide: 'false',
          draggable: true,
          dragSize: 100,
        },
      },
      viewDate: this.$moment(this.$route.query.Date || new Date()).toDate(),
      events: [],
      eventList: undefined,
      swiperEventList: undefined,
      swiperRealLength: undefined,
      videoName1: 'event-video-2022-1.mp4',
      videoName2: 'event-video-2022-2.mp4',
      dummyEventListCount: 0,
    };
  },
  computed: {
    viewRangeTitle() {
      return this.$moment(this.viewDate).format(
        this.$i18n.locale === 'en' ? 'MM.YYYY' : 'YYYY.MM',
      );
    },
  },
  watch: {
    $route() {
      const { hash } = this.$route;
      if (!hash) {
        document.querySelector('.cover-section').classList.remove('hide');
        this.AOSreset();
      }
    },
  },
  created() {
    window.hwisam = this;
    this.retrieveSwiperEvent();
  },
  mounted() {
    if (this.$route.query && this.$route.query.Date) {
      setTimeout(() => {
        window.scrollTo(window, 2750);
      }, 750);
    }
  },
  methods: {
    openCalendar() {
      this.$refs.calendar.showCalendar();
    },
    retrieveEvent() {
      const mCalDate = this.$moment(this.viewDate);
      this.portalApiClient({
        url: APIs.EVENT_LIST,
        data: {
          data: {
            applyStartDate: mCalDate.startOf('month').format('YYYY.MM.DD'),
            applyEndDate: mCalDate.add(1, 'month').startOf('month').format('YYYY.MM.DD'),
            isShow: true
          }
        },
      }).then(({ data }) => {
        if (data.result) {
          const retClassCode = (code) => {
            let cls;
            switch (code) {
              case 61:
                cls = 'business';
                break;
              case 62:
                cls = 'conference';
                break;
              case 63:
                cls = 'networking';
                break;
              case 64:
                cls = 'promotion';
                break;
              case 65:
                cls = 'others';
                break;
              default:
            }
            return cls;
          };
          this.eventList = data.list;
          // 달력 Dot 오류
          // 날짜가 여럿일 경우 위치가 여렷일의 중간에 표시됩니다.
          // 시작일과 종료일의 날짜를 동일하게 노출
          this.events = this.eventList.map((v) => {
            return {
              event_no: v.id,
              start: this.$moment(v.startDate.replace(/\./g, '-')).format('YYYY-MM-DD HH:mm'),
              end: this.$moment(v.startDate.replace(/\./g, '-')).format('YYYY-MM-DD') + ' ' + this.$moment(v.endDate.replace(/\./g, '-')).format('HH:mm'),
              classNames: [retClassCode(v.typeId)],
              title: v.name,
            }
          });
        }
      });
    },
    retrieveSwiperEvent() {
      this.portalApiClient({
        url: APIs.EVENT_PREV_NEXT_LIST,
        data: {
          date1: this.$moment().format('YYYY.MM.DD'),
          page: 1,
          size: 10,
          data: {
            isShow: true
          },
        },
      }).then(({ data }) => {
        if (data.result) {
          const concatList = data.list.concat(data.list2).sort((v1, v2)=>{
            if (v1.startDate > v2.startDate) {
              return 1;
            } else {
              return -1;
            }
          });
          this.swiperEventList = concatList;
          this.$nextTick(() => {
            this.$refs.mySwiper.$swiper.slideTo(
              Math.floor(concatList.length/2) - 1
            );
            const onSwiperProgress = function () {
              const allowRangeIndex = concatList.length - Math.floor(((window.document.body.offsetWidth / 2) - 155) / 310) - 2;
              if (this.activeIndex > allowRangeIndex + 1) {
                this.off('progress', onSwiperProgress);
                this.scrollbar.disableDraggable();
                setTimeout(() => {
                  this.scrollbar.onDragEnd();
                  this.slideTo(allowRangeIndex + 1, 750);
                }, 0);
                setTimeout(() => {
                  this.scrollbar.enableDraggable();
                  this.on('progress', onSwiperProgress);
                }, 500);
              }
            };
            this.$refs.mySwiper.$swiper.on('progress', onSwiperProgress);
          });
        }
      });
    },
    videoChange(event, index) {
      const videoObj = document.getElementById(`eventVideo${index}`);
      const el = event.target;
      // change display
      el.style.display = 'none';
      videoObj.style.display = 'block';
      // play
      videoObj.play();
    },
    onChange(newViewDate) {
      this.viewDate = newViewDate;
      this.retrieveEvent();
    },
    /**
     * https://fullcalendar.io/docs/eventRender
     */
    eventRender(info) {
      const { event, el } = info;
      let time = this.$moment(event.start).format('HH:mm');
      if (event.end) {
        time += ` ~ ${this.$moment(event.end).format('HH:mm')}`
      } else {
        time += ` ~ ${this.$moment(event.start).format('HH:mm')}`
      }
      el.addEventListener('click', this.eventClick(this, event));
      el.innerHTML = `
        <span class='bullet'></span>
        <span class='time'>${time}</span>
        <span class='title'>${event.title}</span>
      `;
    },
    eventClick: (that, event) => () => {
      that.$router.replace(
        `?Date=${that.$moment(that.viewDate).format('YYYYMM')}`,
      );
      that.$router.push({
        name: 'EventDetail',
        params: { eventNo: event.extendedProps.event_no },
        query: { Date: that.$moment(that.viewDate).format('YYYYMM') },
      });
    },
    retEventImg(img) {
      return this.getFileURL(FilePaths.EVENT, img);
    },
    /**
     * AOS init
     * 작동원리: [resize] 이벤트를 강제로 발생시켜 리셋시킴..
     */
    AOSreset() {
      if (window.dispatchEvent) {
        window.dispatchEvent(new Event('resize', { bubbles: true, cancelable: true }));
      } else {
        window.fireEvent('resize');
      }
    },
    /**
     * hash 파라미터 변경시 처리
     * @see mixinHelperUtils의 beforeRouteEnter 가드, beforeRouteUpdate 가드
     */
    onChangeRouteHash(hash) {
      // 해당 hash(id)로 스크롤 이동
      if (hash === '#visual-section') {
        document.querySelector(hash).previousElementSibling.classList.add('hide');
        this.AOSreset();
      }
    },
    getDateString(startDate, endDate) {
      // 이벤트 기간 타이틀 문자열
      if (format.date(startDate) == format.date(endDate)) {
        return `${format.date(startDate)} ${format.time(this.$moment(startDate).format('HHmm'))} 
        - ${format.time(this.$moment(endDate).format('HHmm'))}`;
      } else {
        return `${format.date(startDate)} ${format.time(this.$moment(startDate).format('HHmm'))} 
        - ${format.date(endDate)} ${format.time(this.$moment(endDate).format('HHmm'))}`;
      }
    }
  },
};
</script>

<style>
.event-info dd .date {font-size:100%}

.swiper-scrollbar {
  width: 100% !important;
  opacity: 1 !important;
}
.event-pic img {
  width: 260px;
  height: 260px;
}
.destroy-swiper .swiper-wrapper {display:block; text-align:center}
.destroy-swiper .swiper-slide {display:inline-block;width: initial;margin: 0 20px;}
/* >>> .destroy-swiper .event-info {display:inline-block} */

.calendar-section a {cursor: pointer;}
.vdp-datepicker__calendar header .prev .default:after {
    border-bottom-color: #000 !important;
    border-right-color: #000 !important;
}
.vdp-datepicker__calendar header .next .default:after {
    border-top-color: #000 !important;
    border-left-color: #000 !important;
}
/* .fc-ltr .fc-h-event.fc-not-start, .fc-rtl .fc-h-event.fc-not-end {
  padding-left: 24px;
} */
.event-info dt {
  display: block !important;
  max-width: 260px;
  max-height: 56px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.75;
  letter-spacing: normal;
  color: #666;
  transition: 0.3s;
  white-space: nowrap;
}
</style>
